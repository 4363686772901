import cn from 'classnames'

import { tr } from 'mmfintech-commons'

import { PaymentMethodImage } from './PaymentMethodImage'
import { MethodButtonChecked, MethodButtonWrapper } from './PaymentMethodButton.styled'

import CheckedIcon from '../../assets/icons/checkout-checked-icon.svg'

export const PaymentMethodButton = ({ name, selected, onClick, ...rest }) => (
  <MethodButtonWrapper className={cn({ selected })} onClick={onClick} data-test='button-payment-method' {...rest}>
    <PaymentMethodImage method={name} className={cn({ selected })}>
      {selected && (
        <MethodButtonChecked>
          <img src={CheckedIcon} alt='' />
        </MethodButtonChecked>
      )}
    </PaymentMethodImage>
    {tr(`METADATA.PAYMENT_OPTIONS.${name}`, name)}
  </MethodButtonWrapper>
)
